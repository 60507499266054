import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions to add and remove discounts
const addAutomaticCartDiscount = (discounts) => ({
  type: 'ADD_AUTOMATIC_DISCOUNT',
  payload: discounts
});

const removeAutomaticCartDiscount = (discounts) => ({
  type: 'REMOVE_AUTOMATIC_DISCOUNT',
  payload: discounts
});

const removeUserRemovedDiscount = (discountId) => ({
  type: 'REMOVE_USER_REMOVED_DISCOUNT',
  payload: discountId
});

const addUserRemovedDiscount = (discountId) => ({
  type: 'ADD_USER_REMOVED_DISCOUNT',
  payload: discountId
});

const useDiscounts = (subTotal) => {
  const dispatch = useDispatch();
  const cartData = useSelector(state => state.cartData);
  const items = cartData.items;
  const discounts = useSelector(state => state.discountsData.discounts);
  const appliedDiscounts = cartData.automaticDiscounts;
  const userRemovedDiscounts = cartData.userRemovedDiscounts;

  useEffect(() => {
    let newAppliedDiscounts = [];

    const autoDiscounts = discounts.filter(discount => discount.automatic);

    const checkCondition = (cartItems, condition) => {
      const eligibleItems = cartItems.filter(item =>
        (!condition.item_ids || condition.item_ids.length === 0 || condition.item_ids.includes(item.pid)) &&
        (!condition.category_ids || condition.category_ids.length === 0 || condition.category_ids.includes(item.category))
      );

      if (cartData.type !== null && condition.cartType && cartData.type !== condition.cartType) {
        return false;
      }

      if (condition.type === 'item_count') {
        if (condition.sameItem) {
          return eligibleItems.some(item => item.quantity >= condition.min_items);
        } else {
          const totalItemCount = eligibleItems.reduce((sum, item) => sum + item.quantity, 0);
          return totalItemCount >= condition.min_items;
        }
      } else if (condition.type === 'order_total') {
        const eligibleItemsTotal = eligibleItems.reduce((sum, item) => sum + item.price * item.quantity, 0);
        return eligibleItemsTotal >= condition.min_total;
      }

      return false;
    };

    const calculateApplicableDiscounts = (cartItems, discountList) => {
      return discountList.filter(discount => discount.condition && checkCondition(cartItems, discount.condition));
    };

    const applyHighestPriorityDiscount = (cartItems, discountList) => {
      let applicableDiscounts = calculateApplicableDiscounts(cartItems, discountList);
      applicableDiscounts.sort((a, b) => b.amount - a.amount);
      let processedItems = [...cartItems];

      applicableDiscounts.forEach(discount => {
        const eligibleItems = processedItems.filter(item =>
          (!discount.condition.item_ids || discount.condition.item_ids.length === 0 || discount.condition.item_ids.includes(item.pid)) &&
          (!discount.condition.category_ids || discount.condition.category_ids.length === 0 || discount.condition.category_ids.includes(item.category))
        );

        if (checkCondition(eligibleItems.length > 0 ? eligibleItems : processedItems, discount.condition)) {
          newAppliedDiscounts.push(discount);

          const discountQuantity = Math.floor(eligibleItems.reduce((sum, item) => sum + item.quantity, 0) / discount.condition.min_items);
          let itemsToReduce = discountQuantity * discount.condition.min_items;

          processedItems = processedItems.map(item => {
            if (itemsToReduce > 0) {
              if (item.quantity > itemsToReduce) {
                itemsToReduce -= item.quantity;
                return { ...item, quantity: item.quantity - itemsToReduce };
              } else {
                itemsToReduce -= item.quantity;
                return { ...item, quantity: 0 };
              }
            }
            return item;
          });
        }
      });
    };

    applyHighestPriorityDiscount(items, autoDiscounts);

    newAppliedDiscounts = newAppliedDiscounts.filter(
      discount => !userRemovedDiscounts.includes(discount.id)
    );

    const discountsToRemove = appliedDiscounts.filter(
      discount => !newAppliedDiscounts.some(newDiscount => newDiscount.id === discount.id)
    );

    const discountsToAdd = newAppliedDiscounts.filter(
      discount => !appliedDiscounts.some(appliedDiscount => appliedDiscount.id === discount.id)
    );

    if (discountsToAdd.length > 0) {
      dispatch(addAutomaticCartDiscount(discountsToAdd));
    }

    if (discountsToRemove.length > 0) {
      dispatch(removeAutomaticCartDiscount(discountsToRemove));
    }

    userRemovedDiscounts.forEach(discountId => {
      const discount = discounts.find(d => d.id === discountId);
      if (discount && !checkCondition(items, discount.condition)) {
        dispatch(removeUserRemovedDiscount(discountId));
      }
    });
  }, [items, subTotal, dispatch, appliedDiscounts, discounts, userRemovedDiscounts, cartData.type]);

  return appliedDiscounts;
};

export default useDiscounts;
